import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonConstants } from "../../constants";
import { FETCH_PIPE_LENGTH } from "../../redux/project/types";
import {
  FETCH_PIPE_DATA_GRAPH_SAGA,
  FETCH_PIPE_STATISTICS_SAGA,
  FETCH_PROJECT_STATUS_PIPE_DATA_SAGA,
  FETCH_PROJECT_SUMMARY_DATA_SAGA,
} from "../../redux/reports/types";
import ComingSoon from "../Comingsoon";
import PipeData from "../reports/PipeData";
import ProjectSummary from "../reports/Project-Summary";
import ProjectStatus from "../reports/ProjectStatus";

const { TabPane } = Tabs;

const Reports = () => {
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    commonConstants.reportTabKeys.projectStatus
  );
  const [showGraphicalView, setShowGraphicalView] = useState(false);
  const handleSwitchChange = (checked) => {
    setShowGraphicalView(checked);
  };

  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
  };

  const fetchData = (tabKey) => {
    const projectNumber =
      reducerList?.projectReducer?.selectedProject?.projectNumber;
    if (projectNumber) {
      setIsLoading(true);

      switch (tabKey) {
        case commonConstants.reportTabKeys.projectStatus:
          dispatch({
            type: FETCH_PROJECT_STATUS_PIPE_DATA_SAGA,
            payload: { projectNumber },
            cb: () => setIsLoading(false),
          });
          break;
        case commonConstants.reportTabKeys.pipeData:
          dispatch({
            type: FETCH_PIPE_STATISTICS_SAGA,
            payload: { projectNumber },
            cb: () => setIsLoading(false),
          });
          dispatch({
            type: FETCH_PIPE_DATA_GRAPH_SAGA,
            payload: { projectNumber },
            cb: () => setIsLoading(false),
          });
          dispatch({
            type: FETCH_PIPE_LENGTH,
            payload: { projectNumber, spreadId: "0", subSpreadId: "0" },
            cb: () => setIsLoading(false),
          });
          break;
        case commonConstants.reportTabKeys.projectSummary:
          dispatch({
            type: FETCH_PROJECT_SUMMARY_DATA_SAGA,
            payload: { projectNumber },
            cb: () => setIsLoading(false),
          });
          break;
        default:
          setIsLoading(false);
          break;
      }
    }
  };

  useEffect(() => {
    fetchData(activeTab);
    setShowGraphicalView(false);
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber, activeTab]);

  const { t } = useTranslation();

  return (
    <div className="reports" style={{ display: "flex" }}>
      <div
        style={{
          flex: "30%",
        }}
      >
        <div
          style={{ display: "flex", margin: "17px", padding: "5px 15px" }}
        ></div>
        <Tabs
          className="report-tabs"
          defaultActiveKey={activeTab}
          style={{ flex: "70%", maxHeight: "350px"}} 
          onChange={onChange}
        >
          <TabPane
            tab={t("reportTab.projectStatus")}
            key={commonConstants.reportTabKeys.projectStatus}
            style={{ position: "relative" }}
          >
            <ProjectStatus
              projectStatusPipeData={
                reducerList?.reportsReducer?.projectStatusPipeData
              }
              isLoading={isLoading}
            />
          </TabPane>
          <TabPane
            tab={t("reportTab.pipeData")}
            key={commonConstants.reportTabKeys.pipeData}
            style={{ position: "relative" }}
          >
            <PipeData
              pipeStatistics={reducerList?.reportsReducer?.pipeStatistics}
              pipeDataGraph={reducerList?.reportsReducer?.pipeDataGraph}
              isLoading={isLoading}
              handleSwitchChange={handleSwitchChange}
              showGraphicalView={showGraphicalView}
            />
          </TabPane>
          <TabPane
            tab={t("reportTab.projectSummary")}
            key={commonConstants.reportTabKeys.projectSummary}
          >
            <ProjectSummary
              projectSummaryData={
                reducerList?.reportsReducer?.projectSummaryData
              }
              isLoading={isLoading}
            />
          </TabPane>
          <TabPane
            tab={t("reportTab.pipelineargraph")}
            key={commonConstants.reportTabKeys.pipelineargraph}
            style={{ position: "relative" }}
          >
            <ComingSoon />
          </TabPane>
          <TabPane
            tab={t("reportTab.pipepolarview")}
            key={commonConstants.reportTabKeys.pipepolarview}
            style={{ position: "relative" }}
          >
            <ComingSoon />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Reports;
