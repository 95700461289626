import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { setConfigurationList, startConfigurationLoader } from "./actions.js";
import {
  CREATE_SPREAD_CONFIGURATION_SAGA,
  CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
  DELETE_SPREAD_CONFIGURATION_SAGA,
  DELETE_SUB_SPREAD_CONFIGURATION_SAGA,
  FETCH_SEQUENCE_CONFIGURATION_LIST,
  GENERATE_SEQUENCE_CONFIGURATION_LIST,
  GET_CONFIGURATION_SAGA,
  SORT_SPREAD_CONFIGURATION_SAGA,
  SORT_SUB_SPREAD_CONFIGURATION_SAGA,
  UPDATE_SPREAD_CONFIGURATION_SAGA,
  UPDATE_SUB_SPREAD_CONFIGURATION_SAGA,
  DELETE_SEQUENCE_SPREAD_SAGA,
  FETCH_SEQUENCE_REPORT_SAGA,
  APPROVE_SEQUENCE_SPREAD_SAGA,
  SEND_EMAIL_SEQUENCE_SAGA,
  MIN_MAX_CALCULATION_SAGA,
} from "./types.js";
import { BASE_URL } from "../../config.js";
import fetch from "../../services/fetch";

function* fetchConfigurationList(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(
      `${BASE_URL}/configuration/spread/${apiPayload.projectNumber}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setConfigurationList(response?.data?.spreadList));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  } finally {
    if (data.cb) {
      data.cb();
    }
  }
}

function* sendEmailConfigurationSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(startConfigurationLoader(true));

    const res = yield fetch(`${BASE_URL}/sequence/send-email/sequence`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Email sent successfully",
            duration: 6,
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.error();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
        data.error();
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (e) {
    yield put(startConfigurationLoader(false));
    data.error();
  }
}

function* fetchSequenceReportSaga(data) {
  try {
    let apiPayload = data.payload;

    yield put(startConfigurationLoader(true));

    const queryString = Object.keys(apiPayload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(apiPayload[key])}`
      )
      .join("&");

    const res = yield fetch(`${BASE_URL}/reportgen/sequence?${queryString}`, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
      },
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const blob = yield res.blob();

    if (data.cb) {
      data.cb(blob);
    }

    yield put(startConfigurationLoader(false));
  } catch (e) {
    yield put(startConfigurationLoader(false));
  }
}

function* fetchSequenceList(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const queryString = Object.keys(apiPayload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(apiPayload[key])}`
      )
      .join("&");

    const res = yield fetch(`${BASE_URL}/sequence/sequence?${queryString}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          data.cb(response?.data);
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
    data.cb({});
  }
}

function* generateSequenceConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/sequence/generate-sequence`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          data.cb(response?.data);
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.error({});
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    notification.error({
      message: { error },
    });

    data.error({});
  }
}
function* createSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/configuration/spread`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Spread Created Successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.err();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* updateSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;
    let spreadId = apiPayload.spreadId;
    delete apiPayload.spreadId;

    const res = yield fetch(`${BASE_URL}/configuration/spread/${spreadId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Spread updated successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.err();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* deleteSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;
    let spreadId = apiPayload.spreadId;

    const res = yield fetch(`${BASE_URL}/configuration/spread/${spreadId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Spread deleted successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  } finally {
    if (data.finally) {
      data.finally();
    }
  }
}

function* createSubSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/configuration/sub-spread`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Sub spread Created Successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.err();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* updateSubSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;
    let subSpreadId = apiPayload.subSpreadId;
    delete apiPayload.subSpreadId;

    const res = yield fetch(
      `${BASE_URL}/configuration/sub-spread/${subSpreadId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(apiPayload),
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Sub spread updated successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
          data.cb();
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          data.err();
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* deleteSubSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;
    let subSpreadId = apiPayload.subSpreadId;

    const res = yield fetch(
      `${BASE_URL}/configuration/sub-spread/${subSpreadId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: "Sub spread deleted successfully.",
          });

          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  } finally {
    if (data.finally) {
      data.finally();
    }
  }
}

function* sortSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/configuration/sort/spread`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield fetchConfigurationList({
            payload: {
              projectNumber: apiPayload.projectNumber,
            },
          });
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  } finally {
    if (data.finally) {
      data.finally();
    }
  }
}

function* sortSubSpreadConfigurationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;
    let projectNumber = apiPayload.projectNumber;
    delete apiPayload.projectNumber;

    const res = yield fetch(`${BASE_URL}/configuration/sort/sub-spread`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield fetchConfigurationList({
            payload: {
              projectNumber: projectNumber,
            },
          });
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  } finally {
    if (data.finally) {
      data.finally();
    }
  }
}

function* deleteSpreadSequenceSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/sequence/sequence`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: `${apiPayload.sequenceDeletionFor} sequence deleted successfully`,
          });

          if (data.cb) {
            data.cb();
          }
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });

          if (data.error) {
            data.error();
          }
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    if (data.error) {
      data.error();
    }
    yield put(startConfigurationLoader(false));

    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* approveSequenceSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(`${BASE_URL}/sequence/approve-sequence`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(apiPayload),
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          notification.success({
            message: `Sequence approved successfully`,
          });

          if (data.cb) {
            data.cb();
          }
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          if (data.error) {
            data.error();
          }
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    if (data?.error) {
      data.error();
    }
    yield put(startConfigurationLoader(true));

    notification.error({
      message: { error },
    });
  }
}

function* minMaxCalculationSaga(data) {
  try {
    yield put(startConfigurationLoader(true));

    let apiPayload = data.payload;

    const res = yield fetch(
      `${BASE_URL}/sequence/calculate-min-max-sequence/${apiPayload.projectNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify(apiPayload),
      }
    );

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (res.status) {
      case 200:
        {
          notification.success({
            message: response.message,
          });

          if (data.cb) {
            data.cb();
          }
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
          if (data.error) {
            data.error();
          }
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(startConfigurationLoader(false));
  } catch (error) {
    console.log(error);
    if (data?.error) {
      data.error();
    }
    yield put(startConfigurationLoader(true));

    notification.error({
      message: { error },
    });
  }
}

function* configurationWatcher() {
  yield takeLatest(GET_CONFIGURATION_SAGA, fetchConfigurationList);
  yield takeLatest(
    CREATE_SPREAD_CONFIGURATION_SAGA,
    createSpreadConfigurationSaga
  );
  yield takeLatest(
    UPDATE_SPREAD_CONFIGURATION_SAGA,
    updateSpreadConfigurationSaga
  );
  yield takeLatest(
    DELETE_SPREAD_CONFIGURATION_SAGA,
    deleteSpreadConfigurationSaga
  );
  yield takeLatest(
    CREATE_SUB_SPREAD_CONFIGURATION_SAGA,
    createSubSpreadConfigurationSaga
  );
  yield takeLatest(
    UPDATE_SUB_SPREAD_CONFIGURATION_SAGA,
    updateSubSpreadConfigurationSaga
  );
  yield takeLatest(
    DELETE_SUB_SPREAD_CONFIGURATION_SAGA,
    deleteSubSpreadConfigurationSaga
  );
  yield takeLatest(SORT_SPREAD_CONFIGURATION_SAGA, sortSpreadConfigurationSaga);
  yield takeLatest(
    SORT_SUB_SPREAD_CONFIGURATION_SAGA,
    sortSubSpreadConfigurationSaga
  );
  yield takeLatest(FETCH_SEQUENCE_CONFIGURATION_LIST, fetchSequenceList);
  yield takeLatest(
    GENERATE_SEQUENCE_CONFIGURATION_LIST,
    generateSequenceConfigurationSaga
  );
  yield takeLatest(DELETE_SEQUENCE_SPREAD_SAGA, deleteSpreadSequenceSaga);
  yield takeLatest(FETCH_SEQUENCE_REPORT_SAGA, fetchSequenceReportSaga);
  yield takeLatest(APPROVE_SEQUENCE_SPREAD_SAGA, approveSequenceSaga);
  yield takeLatest(SEND_EMAIL_SEQUENCE_SAGA, sendEmailConfigurationSaga);
  yield takeLatest(MIN_MAX_CALCULATION_SAGA, minMaxCalculationSaga);
}

function* configurationSagas() {
  yield all([call(configurationWatcher)]);
}

export default configurationSagas;
