import { FilePdfOutlined, SendOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonConstants } from "../../constants";
import { sequenceGenerationStatus } from "../../constants/configurationConstants";
import { reportGrapeHelpers } from "../../helpers";
import { FETCH_PROJECT_SUMMARY_REPORT_SAGA } from "../../redux/reports/types";
import EmailPopup from "../EmailPopup";
import CommonLoader from "../Loaders/loader";
import "../../global-styles/light-theme.scss";

const ProjectSummary = ({ isLoading, projectSummaryData }) => {
  const { projectSummaryHelper } = reportGrapeHelpers;
  const chartData = projectSummaryHelper(projectSummaryData);
  const { t } = useTranslation();
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  let dispatch = useDispatch();
  const reducerList = useSelector((state) => state);

  const handleGeneratePDF = async () => {
    try {
      let projectNumber =
        reducerList?.projectReducer?.selectedProject?.projectNumber;
      setIsPdfDownloading(true);
      dispatch({
        type: FETCH_PROJECT_SUMMARY_REPORT_SAGA,
        payload: {
          projectNumber: projectNumber,
        },
        cb: (blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = `${reducerList?.projectReducer?.selectedProject?.projectNumber}_Project-Summary-Report`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          setIsPdfDownloading(false);
        },
      });
    } catch (error) {
      setIsPdfDownloading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const enableDownloadOrSendEmailButton = () => {
    const sequenceStatus =
      reducerList?.projectReducer?.selectedProject?.sequenceGenerationStatus;
    const randomSequenceStatus =
      reducerList?.projectReducer?.selectedProject
        ?.randomSequenceGenerationStatus;
    return sequenceStatus === sequenceGenerationStatus.Completed &&
      randomSequenceStatus === sequenceGenerationStatus.Completed
      ? true
      : false;
  };

  const isAllZeros = (array) => Array.isArray(array) && array.every((num) => num === 0);

  const hasValidData = !(
    isAllZeros(projectSummaryData?.numberOfPipesPerHilo) &&
    isAllZeros(projectSummaryData?.numberOfPipesPerHiloOptifit) &&
    isAllZeros(projectSummaryData?.numberOfPipesPerHiloInRandom)
  );

  const confirmSendEmail = async () => {
    setShowEmailModal(true);
  };

  return (
    <>
      {isLoading ? (
        <div className="initial-sequence-load">
          <CommonLoader />
        </div>
      ) : (
        <div className="projectSummaryTab">
          {hasValidData && (
            <div className="projectSummary-icons">
              <span
                className="custom-tooltip"
                onClick={() => {
                  if (!isPdfDownloading && enableDownloadOrSendEmailButton()) {
                    handleGeneratePDF();
                  }
                }}
                data-tooltip={
                  isPdfDownloading
                    ? "Downloading..."
                    : enableDownloadOrSendEmailButton()
                      ? t("reportIcon.downloadPipeCompatibilityReport")
                      : t("reportIcon.projectReportSummaryNotReady")
                }
              >
                <FilePdfOutlined
                  className={
                    enableDownloadOrSendEmailButton() ? "form-icon" : "hide-icon"
                  }
                />
                {isPdfDownloading && (
                  <div className="downloading-text">
                    <div className="typing-animation">
                      <div className="dot" style={{ "--delay": "0ms" }}></div>
                      <div className="dot" style={{ "--delay": "200ms" }}></div>
                      <div className="dot" style={{ "--delay": "400ms" }}></div>
                    </div>
                  </div>
                )}
              </span>
              <span
                className="custom-tooltip"
                data-tooltip={
                  isSendingEmail
                    ? "Sending email..."
                    : enableDownloadOrSendEmailButton()
                      ? t("reportIcon.shareProjectSummary")
                      : t("reportIcon.projectReportSummaryNotReady")
                }
                onClick={() => {
                  if (!isSendingEmail && enableDownloadOrSendEmailButton()) {
                    confirmSendEmail();
                  }
                }}
              >
                <SendOutlined
                  className={
                    enableDownloadOrSendEmailButton() ? "form-icon" : "hide-icon"
                  }
                />
                {isSendingEmail && (
                  <div className="downloading-text">
                    <div className="typing-animation">
                      <div className="dot" style={{ "--delay": "0ms" }}></div>
                      <div className="dot" style={{ "--delay": "200ms" }}></div>
                      <div className="dot" style={{ "--delay": "400ms" }}></div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          )}
          <div className="peojectSummary">
            {!enableDownloadOrSendEmailButton() || !hasValidData ? (
              <p className="no-data-available">{t("pipeDataGraph.noData")}</p>
            ) : (
              <div id="chart">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={290}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {showEmailModal && (
        <EmailPopup
          setShowEmailModal={setShowEmailModal}
          showEmailModalFor={commonConstants.showEmailModalFor.projectSummary}
          setIsSendingEmail={setIsSendingEmail}
        />
      )}
    </>
  );
};

export default ProjectSummary;
