import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { colorConstants } from "../constants/global-color";
import { FETCH_PIPE_LENGTH } from "../redux/project/types";

export const projectStatusHelper = (projectStatusPipeData) => {
  const dispatch = useDispatch();
  const {
    amberColor,
    redColor,
    greyColor,
    lightgreyColor,
    mossGreen,
    slateBlue,
  } = colorConstants;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const projectName = reducerList?.projectReducer?.selectedProject?.projectName;
  const projectNumber =
    reducerList?.projectReducer?.selectedProject?.projectNumber;

  const initialWebsiteBlogData = projectStatusPipeData?.map(
    (item) => item.count
  );

  const maxPipeScan = Math.max(
    ...projectStatusPipeData.map((item) => item.count)
  );

  const cumulativeData = initialWebsiteBlogData.reduce((accumulator, value) => {
    const cumulativeSum =
      accumulator.length > 0
        ? accumulator[accumulator.length - 1] + value
        : value;
    return [...accumulator, cumulativeSum];
  }, []);

  const availablePipes = reducerList?.projectReducer?.projectPipeCount;
  const usedPipes = reducerList?.projectReducer?.usedPipeCount;
  const validPipes = availablePipes + usedPipes;

  useEffect(() => {
    dispatch({
      type: FETCH_PIPE_LENGTH,
      payload: {
        projectNumber:
          reducerList?.projectReducer?.selectedProject?.projectNumber,
        spreadId: "0",
        subSpreadId: "0",
      },
    });
  }, [reducerList?.projectReducer?.selectedProject?.projectNumber]);

  const series = [
    {
      name: t("projectStatus.NumberOfScanDay"),
      type: "column",
      data: initialWebsiteBlogData,
    },
    {
      name: t("projectStatus.CNS"),
      type: "line",
      data: cumulativeData,
    },
  ];

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      height: 400,
      type: "line",
      foreColor: themeState ? greyColor : lightgreyColor,
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: `${projectName}_${projectNumber}-Pipe_Status`,
          },
        },
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      pan: {
        enabled: false,
      },
    },
    stroke: {
      width: [0, 4, 0],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: projectStatusPipeData?.map((item) => item.date),
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontWeight: "400",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: t("projectStatus.NumberOfPipeScan"),
          style: {
            fontWeight: "400",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        tickAmount: 5,
        min: 0,
        max: maxPipeScan + 10,
        labels: {
          style: {
            fontWeight: "400",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
      },
      {
        opposite: true,
        tickAmount: 5,
        min: 0,
        max: cumulativeData[cumulativeData.length - 1],
        labels: {
          style: {
            fontWeight: "400",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
      },
    ],
    colors: [redColor, amberColor, slateBlue, mossGreen, greyColor],
    legend: {
      show: true,
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    tooltip: {
      theme: "dark",
    },
  };

  return { series, options, validPipes };
};

export const projectSummaryHelper = (projectSummaryData) => {
  const { mossGreen, redColor, greyColor, lightgreyColor } = colorConstants;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const projectName = reducerList?.projectReducer?.selectedProject?.projectName;
  const projectNumber =
    reducerList?.projectReducer?.selectedProject?.projectNumber;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const chartData = useMemo(() => {
    return {
      series: [
        {
          name: t("projectSummary.omnifit"),
          data: projectSummaryData?.numberOfPipesPerHilo,
        },
        {
          name: t("projectSummary.optifit"),
          data: projectSummaryData?.
            numberOfPipesPerHiloOptifit,
        },
        {
          name: t("projectSummary.random"),
          data: projectSummaryData?.numberOfPipesPerHiloInRandom,
        },
      ],
      options: {
        grid: {
          borderColor: themeState ? lightgreyColor : greyColor,
        },
        chart: {
          type: "bar",
          height: 350,
          foreColor: themeState ? greyColor : lightgreyColor,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: `${projectName}_${projectNumber}-Pipe_Compatibility`,
              },
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: t("projectSummary.hilo"),
            style: {
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
          categories: projectSummaryData?.positiveRangeHilo,
          labels: {
            style: {
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        yaxis: {
          title: {
            text: t("projectSummary.users"),
            style: {
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
          tickAmount: 5,
          labels: {
            style: {
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          theme: "dark",
        },
        colors: [redColor, greyColor, mossGreen, greyColor],
        legend: {
          show: true,
          showForSingleSeries: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
      },
    };
  }, [
    projectSummaryData,
    greyColor,
    redColor,
    mossGreen,
    lightgreyColor,
    themeState,
    t,
  ]);

  return chartData;
};
