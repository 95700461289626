import { FilePdfOutlined, SendOutlined } from "@ant-design/icons";
import { Table, Switch } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import LineChart from "./PipeData-Graph";
import PipeDataGraph from "./PipeDataGraph";
import { commonConstants } from "../../constants";
// import { GET_CONFIGURATION_SAGA } from "../../redux/configuration/types";
import { FETCH_PROJECT_REPORT_SAGA } from "../../redux/reports/types";
import EmailPopup from "../EmailPopup";
import CommonLoader from "../Loaders/loader";

const PipeData = ({
  pipeStatistics,
  pipeDataGraph,
  isLoading,
  showGraphicalView,
  handleSwitchChange,
}) => {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const reducerList = useSelector((state) => state);
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleGeneratePDF = async () => {
    try {
      let projectNumber =
        reducerList?.projectReducer?.selectedProject?.projectNumber;
      setIsPdfDownloading(true);
      dispatch({
        type: FETCH_PROJECT_REPORT_SAGA,
        payload: {
          projectNumber: projectNumber,
        },
        cb: (blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = `${reducerList?.projectReducer?.selectedProject?.projectNumber}_Pipe-Data-Report`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          setIsPdfDownloading(false);
        },
      });
    } catch (error) {
      setIsPdfDownloading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const enableDownloadOrSendEmailButton = () => {
    return totalNumberOfPipes ? true : false;
  };

  const [showEmailModal, setShowEmailModal] = useState(false);

  const confirmSendEmail = async () => {
    setShowEmailModal(true);
  };

  const data = [
    {
      key: "1",
      pipeno: "Avg. (all)",
      avgid: pipeStatistics?.avgIDAvg || 0,
      maxid: pipeStatistics?.avgIDMax || 0,
      minid: pipeStatistics?.avgIDMin || 0,
      oor: pipeStatistics?.ooRAvg || 0,
    },
    {
      key: "2",
      pipeno: "Max. (all)",
      avgid: pipeStatistics?.maxIDAvg || 0,
      maxid: pipeStatistics?.maxIDMax || 0,
      minid: pipeStatistics?.maxIDMin || 0,
      oor: pipeStatistics?.ooRMax || 0,
    },
    {
      key: "3",
      pipeno: "Min. (all)",
      avgid: pipeStatistics?.minIDAvg || 0,
      maxid: pipeStatistics?.minIDMax || 0,
      minid: pipeStatistics?.minIDMin || 0,
      oor: pipeStatistics?.ooRIDMin || 0,
    },
  ];

  const columns = [
    {
      title: t("pipeDataTable.pipeNo"),
      dataIndex: "pipeno",
      key: "pipeno",
    },
    {
      title: t("pipeDataTable.avgID"),
      dataIndex: "avgid",
      key: "avgid",
    },
    {
      title: t("pipeDataTable.maxID"),
      dataIndex: "maxid",
      key: "maxid",
    },
    {
      title: t("pipeDataTable.minID"),
      dataIndex: "minid",
      key: "minid",
    },
    {
      title: t("pipeDataTable.oor"),
      dataIndex: "oor",
      key: "oor",
    },
  ];

  const totalNumberOfPipes = reducerList?.projectReducer?.totalPipeCount;
  const availablePipes = reducerList?.projectReducer?.projectPipeCount;
  const usedPipes = reducerList?.projectReducer?.usedPipeCount;

  return (
    <>
      {isLoading ? (
        <div className="initial-sequence-load">
          <CommonLoader />
        </div>
      ) : (
        <div className={showGraphicalView ? "pipeData-chart" : "pipeData"}>
          <div className="table-container">
            <div className="switch-container">
              Summary&nbsp;&nbsp;
              <Switch
                size="default"
                defaultChecked={false}
                onChange={handleSwitchChange}
              />{" "}
              Graph
              <div className="report-icons">
                <span
                  className="custom-tooltip"
                  onClick={() => {
                    if (
                      !isPdfDownloading &&
                      enableDownloadOrSendEmailButton()
                    ) {
                      handleGeneratePDF();
                    }
                  }}
                  data-tooltip={
                    isPdfDownloading
                      ? "Downloading..."
                      : enableDownloadOrSendEmailButton()
                      ? t("pipeDataTableSummary.downloadPipeDataReport")
                      : t("reportIcon.projectReportNotReady")
                  }
                >
                  <FilePdfOutlined
                    className={
                      enableDownloadOrSendEmailButton()
                        ? "form-icon"
                        : "hide-icon"
                    }
                  />
                  {isPdfDownloading && (
                    <div className="downloading-text">
                      <div className="typing-animation">
                        <div className="dot" style={{ "--delay": "0ms" }}></div>
                        <div
                          className="dot"
                          style={{ "--delay": "200ms" }}
                        ></div>
                        <div
                          className="dot"
                          style={{ "--delay": "400ms" }}
                        ></div>
                      </div>
                    </div>
                  )}
                </span>
                <span
                  className="custom-tooltip"
                  data-tooltip={
                    isSendingEmail
                      ? "Sending email..."
                      : enableDownloadOrSendEmailButton()
                      ? t("reportIcon.sharePipeDataReport")
                      : t("reportIcon.projectReportNotReady")
                  }
                  onClick={() => {
                    if (!isSendingEmail && enableDownloadOrSendEmailButton()) {
                      confirmSendEmail();
                    }
                  }}
                >
                  <SendOutlined
                    className={
                      enableDownloadOrSendEmailButton()
                        ? "form-icon"
                        : "hide-icon"
                    }
                  />
                  {isSendingEmail && (
                    <div className="downloading-text">
                      <div className="typing-animation">
                        <div className="dot" style={{ "--delay": "0ms" }}></div>
                        <div
                          className="dot"
                          style={{ "--delay": "200ms" }}
                        ></div>
                        <div
                          className="dot"
                          style={{ "--delay": "400ms" }}
                        ></div>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div className="pipe-summary-tab">
              <span className="header">
                {t("pipeDataTableSummary.totalNumberOfPipes")} :{" "}
                <span className="data">{totalNumberOfPipes}</span>
              </span>
              <span className="header">
                {t("pipeDataTableSummary.availablePipes")} :{" "}
                <span className="data">{availablePipes}</span>
              </span>
              <span className="header">
                {t("pipeDataTableSummary.usedPipes")} :{" "}
                <span className="data">{usedPipes}</span>
              </span>
            </div>
            <div className="pipedata-table">
              {showGraphicalView ? (
                // <LineChart pipeDataGraph={pipeDataGraph} />
                pipeDataGraph?.length > 0 ? (
                  <PipeDataGraph pipeDataGraph={pipeDataGraph} />
                ) : (
                  <div className="pipedata-nodata">
                    {t("pipeDataGraph.noData")}
                  </div>
                )
              ) : (
                <Table columns={columns} dataSource={data} pagination={false} />
              )}
            </div>
          </div>
        </div>
      )}
      {showEmailModal && (
        <EmailPopup
          setShowEmailModal={setShowEmailModal}
          showEmailModalFor={commonConstants.showEmailModalFor.pipeData}
          setIsSendingEmail={setIsSendingEmail}
        />
      )}
    </>
  );
};

export default PipeData;
